import StylableHorizontalMenu_WrapColumnComponent from '../components/StylableHorizontalMenu/viewer/skinComps/WrapColumn/WrapColumn.skin';
import StylableHorizontalMenu_WrapColumnController from '../components/StylableHorizontalMenu/viewer/StylableHorizontalMenu.controller';


const StylableHorizontalMenu_WrapColumn = {
  component: StylableHorizontalMenu_WrapColumnComponent,
  controller: StylableHorizontalMenu_WrapColumnController
};


export const components = {
  ['StylableHorizontalMenu_WrapColumn']: StylableHorizontalMenu_WrapColumn
};

